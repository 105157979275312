<template>
  <div>
    <BaseTable
      ref="sale-invoices-table"
      resource="sale-invoices"
      :resourceStore="'saleInvoice'"
      :columns="columns"
      :filters="baseFilters"
      :totals-configuration="totalsConfiguration"
      :excluded-filters="excludedFilters"
      :loading="loading"
      :disable-pagination="disablePagination"
      :hide-toolbar="hideToolbar"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
      @row-clicked="$router.push({ name: 'viewSaleInvoice', params: { id: $event.id } })"
      @data-loaded="$emit('data-loaded', $event)"
    >
      <template #cell(invoice_date)="data">
        <div> {{ data.item.invoice_date |formatDate }} </div>
        <div
          v-show="data.item.invoice_due_date"
          class="font-small text-light"
        >
          vence {{ data.item.invoice_due_date | formatDate }}
        </div>
        <div
          v-show="data.item.invoice_collection"
          class="font-small text-light"
        >
          cobro {{ data.item.invoice_collection | formatDate }}
        </div>
      </template>
      <template #cell(invoice_due_date)="{ value, item }">
        <span
            v-show="item.status.name === 'Pendiente de cobro' || item.status.name === 'Cobro parcial'"
            :class="(isDueDateExpired(value) ? 'text-danger' : 'text-primary') +' text-uppercase'"
        >
          {{ getDueDateDaysText(value) }}
        </span>
      </template>
      <template #cell(client)="data">
        <div> {{ data.item.client.name }} </div>
      </template>
      <template #cell(job)="{ value }">
        <div> {{ value.name }} </div>
      </template>
      <template #cell(status)="data">
        <StatusBadge
          :status="data.item.status.name"
          :text="data.item.status.name === 'Cobro parcial' ?
            `Cobro parcial ${data.item.percent !== 0 && data.item.percent !== 100 ? `${data.item.percent} % ` : '' }`
          : null"
        />
        <StatusBadge
          v-if="data.item.received_payment_document && data.item.received_payment_document === 1"
          class="mt-1"
          :status="'Rec. Doc. Cobro'"
          :text="'Rec. Doc. Cobro'"
          :variant="'light-success'"
        />
      </template>
      <template #cell(total_retention)="data">
        <div> {{ data.item.total_retention | numberToLocalString }} €</div>
        <div
          v-show="data.item.retention_expiration"
          class="font-small text-light"
        >
          vence {{ data.item.retention_expiration | formatDate }}
        </div>
      </template>
      <template #cell(total_gross)="data">
        <div> {{ data.item.total_gross | numberToLocalString }} €</div>
      </template>
      <template #cell(total_invoice)="data">
        <div> {{ data.item.total_invoice | numberToLocalString }} €</div>
      </template>
      <template #cell(total_liquid)="data">
        <div> {{ data.item.total_liquid | numberToLocalString }} €</div>
      </template>
      <template #cell(actions)="data">
        <feather-icon
          v-show="data.item.observations"
          :id="`popover-target-${data.item.id}`"
          icon="InfoIcon"
          size="18"
          class="text-indigo mr-1"
        />
        <b-popover :target="`popover-target-${data.item.id}`" triggers="hover" placement="top">
          <template #title>Observaciones</template>
          <pre style="background: #ffff;">{{ data.item.observations }}</pre>
        </b-popover>
        <b-link class="d-inline-block text-indigo">
          <feather-icon
            v-b-tooltip.hover
            title="Descargar factura"
            icon="DownloadIcon"
            size="18"
            @click.stop="handlePrintLinkClick(data.item.id)"
          />
        </b-link>
        <b-link
          :to="{ name: 'viewSaleInvoice', params: { id: data.item.id} }"
          class="px-1 d-inline-block text-indigo"
        >
          <feather-icon
            v-b-tooltip.hover
            title="Ver"
            icon="EyeIcon"
            size="18"
          />
        </b-link>
        <b-link
          v-b-tooltip.hover
          title="Eliminar"
          v-access="{
            resource: $data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,
            resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
          }"
          class="d-inline-block text-danger"
          @click="handleDeleteIconClick(data.item)"
        >
          <feather-icon
            v-b-tooltip.hover
            title="Eliminar"
            icon="TrashIcon"
            size="18"
          />
        </b-link>
      </template>
    </BaseTable>
    <SaleInvoicesListFilters
      ref="sale-invoices-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('saleInvoice/setSaleInvoicesFilters', $event)"
    />
  </div>
</template>

<script>
import DateTimeService from '@/shared/services/date-time-service'
import SaleInvoicesApi from '@/api/sale-invoices-api'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import SaleInvoicesListFilters from '@/components/sale-invoices/filters/SaleInvoicesListFilters.vue'
import BaseTable from '@/components/ui/table/BaseTable.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SaleInvoicesTable',
  components: {
    BaseTable,
    SaleInvoicesListFilters,
    StatusBadge,
  },
  props: {
    withJobMode: {
      type: Boolean,
      default: true,
    },
    jobId: {
      type: String,
      default: null,
    },
    jobContractId: {
      type: [String, Number],
      default: null,
    },
    clientId: {
      type: String,
      default: null,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    hideColumnActions: {
      type: Boolean,
      default: false,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    totalsConfiguration: {
      type: Array,
      default: () => [
        { colspan: 6 },
        { label: 'TOTAL BRUTO', key: 'total_gross', unit: '€' },
        { label: 'TOTAL RETENCIÓN', key: 'total_retention', unit: '€' },
        { label: 'TOTAL LÍQUIDO', key: 'total_liquid', unit: '€' },
        { label: 'TOTAL', key: 'total_invoice', unit: '€' },
        { colspan: 1 },
      ],
    },
  },
  data() {
    return {
      loading: false,
      filtersVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      filters: 'saleInvoice/getSaleInvoicesFilters',
    }),
    baseFilters() {
      return {
       job_contract_id: this.jobContractId, client_id: this.clientId, job_id: this.jobId, with_job: this.withJobMode ? 1 : 0, ...this.filters,
      }
    },
    excludedFilters() {
      const result = ['with_job']
      if (this.jobId) {
        result.push('job_id')
      }

      if (this.clientId) {
        result.push('client_id')
      }

      return result
    },
    columns() {
      let result = [
        {
          label: 'NÚMERO',
          key: 'code',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '100px' },
        },
        {
          label: 'FECHA',
          key: 'invoice_date',
          sortable: true,
        },
        {
          label: 'VENCIMIENTO',
          key: 'invoice_due_date',
          sortable: true,
        },
        {
          label: "JOB",
          key: "job",
          sortable: true,
        },
      ]
      if (!this.clientId) {
        result.push({
          label: 'CLIENTE',
          key: 'client',
          sortable: true,
        })
      }

      result = result.concat([
        {
          label: 'ESTADO',
          key: 'status',
          sortable: true,
        },
        {
          label: 'T. BRUTO',
          key: 'total_gross',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { 'min-width': '120px' },
        },
        {
          label: 'RETENCIÓN',
          key: 'total_retention',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'LÍQUIDO A PERCIBIR',
          key: 'total_liquid',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { 'min-width': '175px' },
        },
        {
          label: 'T. FACTURA',
          key: 'total_invoice',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { 'min-width': '120px' },
        },
      ])

      if (!this.hideColumnActions) {
        result.push({
          label: 'ACCIONES',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '210px' },
        })
      }

      return result
    },
  },
  methods: {
    getDueDateDaysText(dueDate) {
      if (!dueDate) {
        return ''
      }

      const days = DateTimeService.getDifferenceInDays(new Date(), new Date(dueDate))
      return this.isDueDateExpired(dueDate) ? `Vencida ${days} dias` : `Vence en ${days} dias`
    },
    isDueDateExpired(dueDate) {
      if (!dueDate) {
        return false
      }

      return new Date() > new Date(dueDate)
    },
    handleClearListFilters() {
      this.$store.commit('saleInvoice/setSaleInvoicesFilters', {})
      this.$refs['sale-invoices-list-filters'].clearFilters()
    },
    async handlePrintLinkClick(saleInvoiceId) {
      this.loading = true
      try {
        await SaleInvoicesApi.printSaleInvoice(saleInvoiceId)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteIconClick(saleInvoice) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro Nº${saleInvoice.code}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await SaleInvoicesApi.delete(saleInvoice.id)
        await this.$refs['sale-invoices-table'].loadData()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
